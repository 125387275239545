html,
body,
#root {
  margin: 0;
  width: 100%;
  font-family: var(--workflow-font-family);
}

.workflow .btn-wrapper a {
  text-decoration: none;
}

.themePrimaryButton {
  border-radius: 2px;
  border: 2px solid transparent;
  font-weight: bold;
  font-size: 19px;
  color: #ffffff;
  background-color: var(--primary-button-color);
  font-family: var(--workflow-font-family);
}

.themeSecondaryButton {
  border-radius: 2px;
  border: 2px solid var(--secondary-button-color);
  font-weight: bold;
  font-size: 19px;
  color: var(--secondary-button-color);
  background-color: #ffffff;
  font-family: var(--workflow-font-family);
}

.themeJumboHeading {
  font-family: var(--workflow-font-family);
  color: #ffffff;
}

.themeJumboBackground {
  background-color: var(--workflow-footer-color);
}

.themeJumboSubheading {
  margin: 0;
  font-family: var(--workflow-font-family);
  color: #ffffff;
}

.themeHeader {
  background-color: var(--workflow-header-color);
  font-family: var(--workflow-font-family);
  font-size: 18px;
  color: var(--workflow-text-color);
}

.themeHeader a {
  text-decoration: none;
}

.themeHeader a:hover {
  color: var(--accent-color); /*generated*/
}

.themeHeader a:visited {
  color: var(--workflow-text-color); /*generated*/
}

.themeContactBackground {
  background-color: #ffffff;
}

.themeContactGetInTouch {
  color: #505660;
  font-family: var(--workflow-font-family);
  text-transform: uppercase;
  font-size: 18px;
}

.themeContactHeading {
  font-family: var(--workflow-font-family);
  font-weight: bold;
  color: var(--workflow-link-color);
  font-size: 24px;
}

.themeContactLink {
  font-size: 18px;
  color: var(--workflow-link-color);
  font-family: var(--workflow-font-family);
  text-decoration: none;
}

.themeContactLink i {
  color: black;
}

.themeContactLabel {
  font-size: 18px;
  font-family: var(--workflow-font-family);
  color: var(--workflow-text-color);
}

.themeContactSection {
  border-bottom: 1px solid #979797;
}

.themeContactSection:last-child {
  border-bottom: none;
}

.themeCardContainer {
  background-color: #ffffff;
  border-top: 4px solid var(--workflow-footer-color);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.themeCardTitle {
  font-size: 22px;
  color: var(--workflow-text-color);
  font-family: var(--workflow-font-family);
  margin-bottom: 20px;
}

.themeCardLink {
  font-size: 17px;
  color: var(--workflow-link-color);
  font-family: var(--workflow-font-family);
}

.themeCardBody {
  font-family: var(--workflow-font-family);
  color: var(--workflow-text-color);
  font-size: 18px;
}

.themeDescriptionContainer {
  background-color: #ffffff;
  padding: 30px;
}

.themeDescriptionHeading {
  font-family: var(--workflow-font-family);
  font-size: 40px;
  border-bottom: 1px solid black;
}

.themeDescriptionContent {
  font-family: var(--workflow-font-family);
}

/* TODO:: remove this once https://citybase.atlassian.net/browse/PLAT-94 */
.themeDescriptionContent .bio {
  display: none;
}

.themeDescriptionContent h4:first-child {
  margin-top: 0;
}

.themeDescriptionContent h5 {
  color: var(--accent-color);
  margin-bottom: 0.25em;
  font-family: var(--workflow-font-family);
  font-size: 20px;
}

.themeDescriptionContent table {
  border: 1px solid #999999;
  margin-bottom: 1.5em;
  width: 100%;
  border-spacing: 0px;
}

.themeDescriptionContent table th {
  background-color: var(--accent-color);
  color: #ffffff;
  font-family: var(--workflow-font-family);
  text-align: left;
  font-size: 18px;
  padding: 0.5em;
  border-bottom: 1px solid #999999;
}

.themeDescriptionContent table td {
  font-family: var(--workflow-font-family);
  text-align: left;
  font-size: 16px;
  padding: 0.5em;
  border-bottom: 1px solid #999999;
}

.themeDescriptionContent table tr:last-child td {
  border-bottom: 0px;
}

.themeCardListingTitle {
  font-size: 27px;
  font-weight: 700;
  font-family: var(--workflow-font-family);
  color: var(--workflow-text-color);
}

.themeCardListingTitle::before {
  background-color: var(--workflow-link-color);
}

.themeBioWrapper {
  background-color: #ffffff;
}

.themeBioContainer {
  border-top: 2px solid #979797;
}

.themeBioHeading {
  font-family: var(--workflow-font-family);
  font-size: 40px;
}

.themeBioContent {
  font-family: var(--workflow-font-family);
}

.themeBioImage img {
  height: 125px;
  width: 125px;
  border-radius: 50%;
}

.themePageBackground {
  background-color: #f6f6f9;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.themeFooter {
  background-color: var(--workflow-footer-color);
  font-family: var(--workflow-font-family);
  font-size: 18px;
}

.themeFooter a {
  color: #ffffff;
  text-decoration: none;
}

.themeFooter a:hover {
  color: var(--workflow-link-color);
}

.themeFooter a:visited {
  color: #ffffff;
}

.themeLoading {
  background: linear-gradient(
    to right,
    #ffffff 10%,
    rgba(255, 255, 255, 0) 42%
  );
}

.themeLoading:before {
  background-color: var(--workflow-footer-color);
}

.themeWorkflowContainer,
.themeWorkflowContainer * {
  font-family: var(--workflow-font-family);
}

.themeWorkflowContainer .button-group button.selected,
.themeWorkflowContainer .btn.primary {
  background-color: var(--accent-color);
}

.themeWorkflowContainer .button-group button:hover,
.themeWorkflowContainer .accordion .title:hover {
  filter: brightness(95%);
  border-color: var(--workflow-link-color);
  color: var(--workflow-link-color);
}

.themeWorkflowContainer .accordion-wrapper .open .title {
  border-left-color: var(--workflow-link-color);
  color: var(--workflow-link-color);
}

.themeWorkflowContainer .accordion-wrapper .open .title::before {
  color: var(--workflow-link-color);
}

.themeWorkflowContainer .button-group button.selected:hover {
  color: #ffffff;
}

.themeWorkflowContainer .btn.secondary {
  border-color: var(--secondary-button-color);
  color: var(--secondary-button-color);
}

.themeWorkflowContainer .workflow .field:not(.button-group) {
  border: 1px solid #6d717e;
  padding: 16px;
  background-color: #ffffff;
}

.themeWorkflowContainer .workflow .field input,
.themeWorkflowContainer .workflow .field select,
.themeWorkflowContainer .workflow .field.checkbox-group {
  border: none;
}

.themeWorkflowContainer .workflow .field.checkbox-group {
  padding: 0;
}

.themeWorkflowContainer .workflow .field input:focus {
  background: none;
}

.themeAuthHeader {
  font-size: 42px;
  font-family: var(--workflow-font-family);
  color: #ffffff;
}

.themeAuthHeader::before {
  background-color: var(--workflow-link-color);
}

.themeWorkflowContainer .workflow .field:focus-within,
.themeWorkflowContainer .workflow .btn:focus,
.themeWorkflowContainer .workflow .field button:focus,
.themeWorkflowContainer .workflow .field input[type="checkbox"]:focus,
.themeWorkflowContainer .workflow .field input[type="radio"]:focus {
  outline: 3px solid #3181e3;
  outline-offset: 2px;
  z-index: 10;
}

.themeWorkflowContainer .workflow .field.button-group:focus-within,
.themeWorkflowContainer .workflow .field.radio-group:focus-within,
.themeWorkflowContainer .workflow .field.checkbox-group:focus-within {
  outline: none;
}

.themeWorkflowContainer .workflow .ReactTable input[type="checkbox"] {
  -moz-appearance: initial;
  position: relative;
  min-width: 13px;
  min-height: 13px;
  box-sizing: content-box;
  border: 1px solid #cfd4de;
  background: #fff;
  padding: 0;
}

.themeWorkflowContainer
  .workflow
  .ReactTable
  .rt-td-val
  input[type="checkbox"]:focus,
.themeWorkflowContainer
  .workflow
  .ReactTable
  .rt-th
  input[type="checkbox"]:focus {
  border-color: var(--primary-button-color);
}

.themeWorkflowContainer
  .workflow
  .ReactTable
  .rt-td-val
  input[type="checkbox"]::before,
.themeWorkflowContainer
  .workflow
  .ReactTable
  .rt-th
  input[type="checkbox"]::before {
  display: none;
}

.themeWorkflowContainer
  .workflow
  .ReactTable
  .rt-td-val
  input[type="checkbox"]:focus::before,
.themeWorkflowContainer
  .workflow
  .ReactTable
  .rt-th
  input[type="checkbox"]:focus::before {
  position: absolute;
  display: inline-block;
  height: 100%;
  width: 100%;
  min-width: 21px;
  min-height: 21px;
  box-sizing: border-box;
  content: "";
  border-radius: 4px;
  left: -4px;
  top: -4px;
  border: 2px solid var(--primary-button-color);
}
